.scroll-table {
	overflow-y: auto;
    max-height: 89vh;
}

.table {
	width: 100%;
}

th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
  padding-top: 3rem !important;
}

th span {
	cursor: pointer;
}