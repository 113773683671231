::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 3px 3px; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

select.custom-select:focus {
    border-color: #009688;
    box-shadow: none;    
}

body {
  overflow: hidden;
}

.btn-link {
  color: #269DCF !important;
}

.bd-callout {
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #eee;
    border-left-width: .25rem;
    border-radius: .25rem
}

.bd-callout h4 {
    margin-top: 0;
    margin-bottom: 1rem
}

.bd-callout p:last-child {
    margin-bottom: 0
}

.bd-callout code {
    border-radius: .25rem
}

.bd-callout+.bd-callout {
    margin-top: -.25rem
}

.bd-callout-info {
    border-left-color: #5bc0de
}

.bd-callout-info .btn-primary {
	background-color: #5bc0de;
	border-color: #5bc0de;
}

.bd-callout-info h4 {
    color: #5bc0de;
}

.bd-callout-warning {
    border-left-color: #f0ad4e
}

.bd-callout-warning .btn-primary {
	background-color: #f0ad4e;
	border-color: #f0ad4e;
}

.bd-callout-warning h4 {
    color: #f0ad4e
}

.bd-callout-danger {
    border-left-color: #d9534f
}

.bd-callout-danger h4 {
    color: #d9534f
}

.bd-callout-danger .btn-primary {
	background-color: #d9534f;
	border-color: #d9534f;
}

.custom-control-label:after, 
.custom-control-label:before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
}

.custom-control-label:before, 
.custom-file-label, 
.custom-select {
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.custom-switch .custom-control-label {
}
.custom-switch .custom-control-label:before {
    width: 1.75rem;
    pointer-events: all;
    border-radius: .5rem;
}

.custom-control-label:after, 
.custom-control-label:before {
    position: absolute;
    top: 0;
    left: -2rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
}

.custom-control-label:after {
    background: no-repeat 50%/50% 50%;
}

.custom-switch .custom-control-label:after {
    top: 0.1rem;
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: .5rem;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
}

.custom-switch .custom-control-input:checked~.custom-control-label:after {
    background-color: #fff;
    -webkit-transform: translateX(.75rem);
    transform: translateX(.75rem);
}

.custom-switch {
	padding-left: 2.25rem;
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em;
}

.react-datepicker-popper {
    z-index: 3;
}

.bg-app {
    background: #f0dd0c;
}

.bg-cs {
    background: #aa02d9;
}

.bg-recall {
    background: #d96e02;
}

@keyframes spinner-border {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 992px) {
	.modal-xl {
	    max-width: 800px;
	}
}

@media (min-width: 1200px) {
	.modal-xl {
	    max-width: 1140px;
	}
}

.rbt-input-multi {
    cursor: text;
    overflow: hidden;
    position: relative;
}

.rbt-input-multi .rbt-input-wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;
    margin-top: -1px;
    overflow: hidden;
}

.rbt-token {
    background-color: #e7f4ff;
    border: 0;
    border-radius: .25rem;
    color: #007bff;
    display: inline-block;
    line-height: 1em;
    margin: 1px 3px 2px 0;
    padding: 4px 7px;
    position: relative;
}

.rbt-token-removeable {
    cursor: pointer;
    padding-right: 21px;
}

.rbt-token .rbt-token-remove-button {
    bottom: 0;
    color: inherit;
    font-size: inherit;
    font-weight: normal;
    opacity: 1;
    outline: none;
    padding: 3px 7px;
    position: absolute;
    right: 0;
    text-shadow: none;
    top: -2px;
}